import Resource from "./resource.js";
export class ServerInfo extends Resource {
    constructor(client) {
        super(client, {
            path: "/",
            getBaseUrl: () => client.baseUrl,
        });
    }
    find = this.makeRequest({
        method: "GET",
        path: "/admin/serverinfo",
    });
    findEffectiveMessageBundles = this.makeRequest({
        method: "GET",
        path: "/resources/{realm}/{theme}/{locale}",
        urlParamKeys: ["realm", "theme", "locale"],
        queryParamKeys: ["themeType", "source"],
    });
}
